<template>
    <div class="container-fluid">

        <div class="box-header">
            <div class="row">
                <div class="col-md-4">
                    <h4 class="title-in-h">{{$t('revenue.index.title')}}</h4>
                    <label v-show="info_page.status==='active'">{{$t('revenue.index.has')+' '+ info_page.actives+' '+$t('revenue.index.a_has_plural') }}</label>
                    <label v-show="info_page.status==='caducate'">{{$t('revenue.index.has')+' '+ info_page.actives+' '+$t('revenue.index.c_has_plural') }}</label>
                    <label v-show="info_page.status==='inactive'">{{$t('revenue.index.has')+' '+ info_page.actives+' '+$t('revenue.index.i_has_plural') }}</label>
                </div>

                <div class="col-md-8 pt-2">
                    <button class="btn btn-wd   ml-1 mr-1" :class="info_page.status==='active'?'btn-success':'btn-outline'" @click="change_status('active')">{{$t('revenue.filter.vigencies')}}</button>
                    <button class="btn btn-wd   ml-1 mr-1" :class="info_page.status==='caducate'?'btn-success':'btn-outline'" @click="change_status('caducate')">{{$t('revenue.filter.caducated')}}</button>
                    <button class="btn btn-wd   ml-1 mr-1" :class="info_page.status==='inactive'?'btn-success':'btn-outline'" @click="change_status('inactive')">{{$t('revenue.filter.inactives')}}</button>
                </div>
            </div>
        </div>


    <div class="row">

        <vue-topprogress ref="topProgress"></vue-topprogress>
        <div class="col-12">
            <card v-loading="screenLoading"
                  element-loading-lock="true"
                  element-loading-customClass="min-height-large"
                  :element-loading-text="screenText"
                  element-loading-spinner="el-icon-loading">
                <div>
                    <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                        <div class="mb-3" style="width: 250px">

                            <l-button class="btn-default" @click="createItem()" v-if="writting_permission" >{{$t('revenue.index.new')}}</l-button>
                        </div>
                        <el-input type="search"
                                  class="mb-3"
                                  style="width: 200px"
                                  :placeholder="$t('revenue.index.search') "
                                  v-model="searchQuery"
                                  aria-controls="datatables"/>
                    </div>
                    <div class="col-sm-12">

                        <el-table class="table-bigboy"
                                  style="width: 100%"
                                  :data="displayData">
                            <el-table-column min-width="80"  :label="$t('revenue.index.name') ">
                                <template slot-scope="{row}">
                                    <div class="lbl-container">
                                        <label>{{row.name}}</label>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column min-width="80"  :label="$t('revenue.index.period') ">
                                <template slot-scope="{row}">
                                    <div class="lbl-container text-center">
                                        <label>{{formatDate(checkDate(row.dates, 'min'), 'normal')}}</label>
                                        <label>{{formatDate(checkDate(row.dates, 'max'), 'normal')}}</label>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column min-width="500"  :label="$t('revenue.index.conditions') ">
                                <template slot-scope="{row}">
                                    <div class="row pt-2 pb-2" v-for="(rateplan, rateIdx) in row.rate_config_info" :class="rateIdx%2?'bg-light':''">
                                        <div class="col-2">
                                          <label> {{rateplan.name}}</label>
                                        </div>

                                        <div class="col-10 ">
                                            <div class="row">
                                                <div class="col-3">

                                                </div>
                                                <div class="col-3 pl-1 " v-for="x in 3"  :class="x===3?'pr-2':'pr-1'" v-show="rateplan.columnsCustom[x]">
                                                    <div class="row no-gutters" >
                                                        <div class="col-12 text-center">
                                                            <label class="font-weight-bold">Condición {{x}}</label>
                                                        </div>
                                                    </div>
                                                    <div class="row no-gutters" >
                                                        <div class="col-4 pl-c1 pr-c1 bol-1 bot-1 text-center  item-rule header-r" >
                                                            <span>Ventas</span>
                                                        </div>
                                                        <div class="col-4 pl-c1 pr-c1 bot-1 text-center  item-rule header-r">
                                                            <span>Nivel</span>
                                                        </div>
                                                        <div class="col-4 pl-c1 pr-c1 bot-1 bor-1 text-center  item-rule header-r">
                                                            <span>Tarifa</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" v-for="(rule_config, idxKy) in rateplan.rate_config_rules">
                                                <div class="col-3 pl-1 pr-1 overflow-hidden">
                                                    <label class="label-row"> {{rule_config.room_name}}</label>
                                                </div>
                                                <div class="col-3 pl-1 " v-for="x in 3" :class="x===3?'pr-2':'pr-1'" v-show="rateplan.columnsCustom[x]">
                                                    <div class="row no-gutters ">
                                                        <div class="col-4 pl-c1 pr-c1 text-center bol-1 item-rule" :class="idxKy+1===rateplan.rate_config_rules.length?'bob-1':''">
                                                            <span v-if="rule_config.conditions.length>=x">{{rule_config.conditions[x-1].room_nights}}</span>
                                                            <span v-else></span>
                                                        </div>
                                                        <div class="col-4 pl-c1 pr-c1 text-center item-rule" :class="idxKy+1===rateplan.rate_config_rules.length?'bob-1':''">
                                                            <span v-if="rule_config.conditions.length>=x">{{rule_config.conditions[x-1].rate_type}}</span>
                                                            <span v-else></span>
                                                        </div>
                                                        <div class="col-4 pl-c1 pr-c1 text-center bor-1 item-rule" :class="idxKy+1===rateplan.rate_config_rules.length?'bob-1':''">
                                                            <span v-if="rule_config.conditions.length>=x">{{rule_config.conditions[x-1].price}}</span>
                                                            <span v-else></span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </template>
                            </el-table-column>
                            <el-table-column  min-width="100"  :label="$t('form.actions')" v-if="writting_permission">
                                <template slot-scope="{row}">
                                    <div class="text-center">
                                        <l-switch v-model="row.status" type="primary" on-text="Activo" off-text="Inactivo" @input="updateStatus(row._id, row.status)"></l-switch>

                                        <a v-tooltip.top-center="$t('form.edit')" class="btn-warning btn-simple btn-link ml-1"
                                           @click="handleEdit(row._id)"><i
                                                class="fa fa-edit"></i></a>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                    <div class="">
                        <p class="card-category" v-if="total>0">
                            {{$t('form.showing_a')}} {{from + 1}} {{$t('form.showing_b')}} {{to}}
                            {{$t('form.showing_c')}} {{total}} {{$t('form.showing_d')}} </p>
                    </div>
                    <l-pagination class="pagination-no-border"
                                  v-model="pagination.currentPage"
                                  :per-page="pagination.perPage"
                                  :total="pagination.total">
                    </l-pagination>
                </div>
            </card>
        </div>
    </div>
        <div class="box-footer" :class="(records.length)?'showme':''">
            <div class="box-inside text-center">
                <button class="btn btn-danger btn-wd btn-default ml-1 mr-1"  @click="cancelForm">Cancelar</button>
                <button class="btn btn-success btn-wd btn-default ml-1 mr-1"  @click="saveChanges">Guardar</button>
            </div>
        </div>
    </div>

</template>
<script>
    import {Table, TableColumn, Select, Option} from 'element-ui'
    import {Pagination as LPagination, Breadcrumb, BreadcrumbItem, Switch as LSwitch} from 'src/components/index'
    //import users from 'src/pages/Dashboard/Tables/users'
    import swal from 'sweetalert2'
    import Fuse from 'fuse.js'
    import moment from 'moment';
    import RuleService from '../../../js/services/RuleService';
    import ContractService from '../../../js/services/ContractService';
    import RateService from '../../../js/services/RateService';
    import EventBus from "../../../js/helpers/EventBus";
    import { vueTopprogress } from 'vue-top-progress'
    import Loading from 'src/pages/Dashboard/Layout/LoadingMainPanel.vue'
    import LogFunctions from '../../../js/functions/LogFunctions';
    import LogService from '../../../js/services/LogService';

    const ruleService = new RuleService();
    const  rateService = new RateService();
    const contractService = new ContractService();
    import Settings from "src/js/helpers/Settings";
    let settings = new Settings();
    const logFunctions = new LogFunctions();
    const logService = new LogService();
    import { mapGetters } from "vuex";

    export default {
        components: {
            LPagination,
            Breadcrumb,
            BreadcrumbItem,
            LSwitch,
            [Select.name]: Select,
            [Option.name]: Option,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            vueTopprogress
        },
        computed: {
            ...mapGetters(["currentUser","selected_property","writting_permission"]),
            pagedData() {
                return this.tableData.slice(this.from, this.to)
            },
            /***
             * Searches through table data and returns a paginated array.
             * Note that this should not be used for table with a lot of data as it might be slow!
             * Do the search and the pagination on the server and display the data retrieved from server instead.
             * @returns {computed.pagedData}
             */
            queriedData() {
                let result = this.tableData
                if (this.searchQuery !== '') {
                    //result = this.fuseSearch.search(this.searchQuery)
                    var fuse = new Fuse(result, this.options);
                    result = fuse.search(this.searchQuery);
                    this.pagination.total = result.length
                }
                return result.slice(this.from, this.to)
            },
            to() {
                let highBound = this.from + this.pagination.perPage
                if (this.total < highBound) {
                    highBound = this.total
                }
                return highBound
            },
            from() {
                return this.pagination.perPage * (this.pagination.currentPage - 1)
            },
            total() {
                this.pagination.total = this.tableData.length
                return this.tableData.length
            },

            displayData() {

                let result = JSON.parse(JSON.stringify(this.tableData))

                //onsole.log('cP', this.pagination.currentPage)
                /*if(this.pagination.currentPage>1){

                    this.pagination.activePage = this.pagination.currentPage;
                }*/
                if (this.searchQuery !== '') {


                    var fuse = new Fuse(result, this.options);
                    result = fuse.search(this.searchQuery);
                    //result = this.fuseSearch.search(this.searchQuery)
                    this.pagination.total = result.length
                }
                if (!this.tableData || this.tableData.length === 0) return [];

                if(this.pagination.activePage){
                    this.pagination.currentPage = this.pagination.activePage;
                    this.pagination.activePage = 0;
                }


                return result.slice(this.from, this.to)

            },
        },
        data() {
            return {
                log_page:{
                    action: null,
                    section: null,
                    property_id: null,
                    data: [],
                    date: new Date(),
                    object_id: null,
                    user_id: null,
                },
                screenLoading: false,
                screenText: '',
                records:[],
                info_page:{
                    original_info:{},
                    section_name: 'rule',
                    status: 'active',
                    actives: 0,
                    inactives: 0,
                    contracts: []
                },
                rateplans: [],
                rates: [],
                items: [],
                items_i: [],
                pagination: {
                    perPage: 10,
                    currentPage: 1,
                    perPageOptions: [5, 10, 25, 50],
                    total: 0
                },
                searchQuery: '',
                propsToSearch: ['name'],
                tableData: [],
                fuseSearch: null,
                options : {
                    shouldSort: true,
                    matchAllTokens: true,
                    threshold: 0.5,
                    location: 0,
                    distance: 100,
                    maxPatternLength: 32,
                    minMatchCharLength: 1,
                    keys: [
                        "name"
                    ]
                },
                section: 'Revenue',
            }
        },
        created(){
            this.chargerItems(this.selected_property._id);
             this.$store.dispatch('CHANGE_SECTION',this.section);
        },
        methods: {
            changeProperty(){
                EventBus.$on('CHANGE_PROPERTY', (property_id) => {
                    if(this.$route.name==='Revenues'){
                        //console.log(this.$route.name, 'rooms')
                        this.chargerItems(property_id);
                    }
                })
            },


            initLoading(text = 'Cargando...') {
                if(text==='Cargando...'){
                    this.$store.dispatch('CHANGES_ACTIVES', false);
                    this.records = [];
                }
                this.screenLoading = true;
                this.screenText = text;
            },
            async chargerItems(property_id = null){

                //console.log('contracts', contracts);
                this.info_page.property_id = property_id;
                await this.chargeRates();

                this.change_status('active');
                this.configLogs(property_id, this.info_page.section_name);
            },
            async createLog(object_id, action, data = []){
                let paramsLog= this.log_page;
                paramsLog.object_id = object_id;
                //paramsLog.section = logFunctions.findSection(this.info_page.section_name)._id
                paramsLog.action = action;
                paramsLog.data = data;
                paramsLog.date = moment().format('YYYY-MM-DD HH:mm:ss');
                await logService.create(paramsLog);
            },
            configLogs(property_id, section_name){
                this.log_page.section = logFunctions.findSection(section_name)._id
                this.log_page.property_id = property_id;
                this.log_page.user_id = this.currentUser._id;
                this.log_page.action = 2;
                this.log_page.date = moment().format('YYYY-MM-DD HH:mm');
            },
            async chargeRates(){
                let property_id = this.info_page.property_id;
                let arrayDates = [];

                let date_end = moment().format('YYYY-MM-DD');
                let date_start = moment().format('YYYY-MM-DD');
                let rates = await rateService.getRatesTarYRest({
                    property: property_id,
                    from: date_start,
                    to: date_end,
                    rateplan_status: 1

                })
                let newRatesArray = []
                rates = rates.data.data.rooms;

                rates.forEach(rate=>{

                    //console.log('rate', rate);
                    rate.rates_plan.forEach(rateplan=>{


                        let existRateplan = newRatesArray.find(item => item.rateplan_id === rateplan.rateplan_data._id);

                        if(existRateplan){
                            existRateplan.rate_config_rules.push({
                                room: rate._id.name,
                                rate_config_id: rateplan.rateconfig_id,
                            });
                        }else{
                            let modelInsert = {
                                rateplan_name: rateplan.rateplan_data.name,
                                rateplan_id: rateplan.rateplan_data._id,
                                rate_config_rules: [{
                                    room: rate._id.name,
                                    rate_config_id: rateplan.rateconfig_id,
                                }]
                            };
                            newRatesArray.push(modelInsert);
                        }

                    })

                })


                //console.log('rates', rates, newRatesArray);

                this.rates = newRatesArray;
            },
            createStructure(config_rule){
                let rateplans = config_rule.rateplans;
                config_rule.rate_config_info = []
                let ratesInfo = this.rates;

                //console.log('config_rule', config_rule, ratesInfo);
                rateplans.forEach(rateplan=>{

                    let infoRateplan = ratesInfo.find(item => item.rateplan_id === rateplan);
                    if(infoRateplan){


                        let modelRateplan ={
                            name: infoRateplan.rateplan_name,
                            columnsCustom: ['',true, false, false],
                            rate_config_rules: []
                        }

                        infoRateplan.rate_config_rules.forEach(item=> {
                            let findConfigId =  config_rule.rate_config_rules.find(element => element.rate_config_id === item.rate_config_id);
                            if(findConfigId){
                                findConfigId.room_name = item.room;
                                modelRateplan.rate_config_rules.push(findConfigId)
                                if(findConfigId.conditions.length>1){
                                    modelRateplan.columnsCustom[2] = true;
                                }
                                if(findConfigId.conditions.length>2){
                                    modelRateplan.columnsCustom[3] = true;
                                }
                            }
                        });
                        config_rule.rate_config_info.push(modelRateplan);
                    }
                })

            },

            async change_status(type){
                this.initLoading();
                let items = [];
                this.info_page.status = type;

                let property_id = this.selected_property._id;

                //console.log(this.info_page.actives, this.info_page.inactives)
                let params = {};


                if(type === 'active'){
                    params = {
                        property_id: property_id,
                        status: true,
                        expired: false
                    }
                }else if(type === 'caducate'){
                    params = {
                        property_id: property_id,
                        expired: true,
                        status: true,

                    }
                }else if(type === 'inactive'){
                    params = {
                        property_id: property_id,
                        status: false,
                    }
                }

                let rules =  await ruleService.getRules(params);
                rules = rules.data.data.rules;

                let idx = 0;
                while (idx<rules.length){
                    this.createStructure(rules[idx]);
                    idx++;
                }




                //console.log('this.items',  rules)
                this.info_page.actives = rules.length;
                this.tableData = rules;

                /*this.pagination.activePage = 1;

                this.info_page.actives = rateplans.length;
                this.tableData = rateplans;*/

                this.closeLoading();
            },
            consoleLog(title, info){
              console.log(title);
                console.log('---------------------------------');
                console.log(info);
                console.log('*********************************');
            },


            closeLoading(){
                this.screenLoading = false;
            },
            rateplanName(rateplan_id){
                let infoRateplan = this.rateplans.find(item => item._id === rateplan_id);
                let returnText = '';
                if(infoRateplan){
                    returnText = infoRateplan.name;
                }
                return returnText;

            },

            handleEdit(id) {
                this.$router.push({
                    name: 'editRevenue',
                    params: {
                        id: id
                    }
                })
            },
            handleDelete(row) {
                swal({
                    title: this.$t('form.question_delete'),
                    text: this.$t('form.warning_delete'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    cancelButtonClass: 'btn btn-danger btn-fill',
                    confirmButtonText: this.$t('form.yes_deleted'),
                    cancelButtonText: this.$t('form.cancel'),
                    buttonsStyling: false
                }).then((result)=> {
                    if (result.dismiss !== 'cancel') {
                        /*roomService.deleteRoom(row.id).then(response => {
                            swal({
                                title: this.$t('form.deleted'),
                                text: this.$t('form.been_deleted'),
                                type: 'success',
                                confirmButtonClass: 'btn btn-success btn-fill',
                                buttonsStyling: false
                            })
                            let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
                            if (indexToDelete >= 0) {
                                this.tableData.splice(indexToDelete, 1)
                            }
                        });*/
                    }
                });



            },
            createItem(){
                this.$router.push({
                    name: 'CreateRevenue'
                })
            },
            formatDate(date, typeD=null) {
                let value_r = null;
                switch (typeD) {
                    case 'short':
                        value_r = moment(date).utc().format('DD/MM');
                        break;

                    case 'normal':
                        value_r = moment(date).utc().format('DD/MM/YYYY');

                        break;
                    default:
                        value_r = date.utc().format('YYYY-MM-DD');
                        break;

                }
                return value_r
            },
            cancelForm(){
                this.chargerItems(this.selected_property._id);
                this.info_page.status = true;
                this.records = [];
            },


            updateStatus(id, status){
                let records = this.records;
                status = !status;

                let idx = records.findIndex(record => record._id === id);
                if(idx>=0){
                    records.splice(idx, 1);
                }else{
                    records.push({
                        _id: id,
                        status: status
                    })
                }
            },
            checkTimeZone(date){
                let gTimeZone = settings.getTimezone();

                let tzdetect =  moment(date)
                    .utc(gTimeZone)

                return tzdetect;

            },
            checkDate(datesSelected, action){

                //datesSelected = datesSelected.sort();
                let valueReturn = datesSelected[0];

                if(action==='max'){
                    valueReturn = datesSelected[datesSelected.length - 1];
                }
                return valueReturn;
            },
            async saveChanges(){
                let records = this.records;

                let idx  = 0;
                while (idx < records.length){
                    let item = records[idx];
                    let id =item._id;
                    let indexToDelete = this.tableData.findIndex((tableRow) => tableRow._id === id)
                    if (indexToDelete >= 0) {
                        this.tableData.splice(indexToDelete, 1)
                    }
                    let ruleResponse = await ruleService.toggleStatus(id)
                    console.log('ruleResponse', ruleResponse)
                    ruleResponse = ruleResponse.data.data.rule;
                    if(ruleResponse.status === false){
                        await this.createLog(ruleResponse._id, 2, []);
                    }else{
                        let data =  [{
                            parameter : "status",
                            old : false,
                            new : true
                        }];
                        await this.createLog(ruleResponse._id, 1, data);
                    }



                    idx++;
                }

                this.records = [];

               /* setTimeout(() => {
                    let indexToDelete = this.tableData.findIndex((tableRow) => tableRow._id === id)
                    if (indexToDelete >= 0) {
                        this.tableData.splice(indexToDelete, 1)
                    }

                    if(status===true){
                        indexToDelete = this.items_i.findIndex((tableRow) => tableRow._id === id);
                        if (indexToDelete >= 0) {
                            this.items_i.splice(indexToDelete, 1)
                        }
                        status = 'true';

                    }else{
                        indexToDelete = this.items.findIndex((tableRow) => tableRow._id === id);
                        if (indexToDelete >= 0) {
                            this.items.splice(indexToDelete, 1)
                        }
                        status = 'false';
                    }

                    roomService.updateStatus(id, status).then(response => {

                        let room = response.data.data.room;
                        if(room.status===true){
                            this.items.push(room)
                        }else{
                            this.items_i.push(room)
                        }

                        this.info_page.actives =  this.items.length;
                        this.info_page.inactives =  this.items_i.length;
                    });
                }, 800);*/
            },

            trans_status(status){
                let result = 'Inactivo';
                if(status){
                    result = 'Activo';
                }
                return result
            },

        },
        mounted() {
            this.changeProperty()
        }
    }
</script>
<style lang="scss">
    .el-table{
        table{
            .cell{
                word-break: break-word;
                .lbl-container{
                    label{
                        word-break: break-word;
                    }
                }
            }
        }

    }
    .custom-lbl{
        label{
            color: #23CCEF;
            font-weight: 600;
        }
        span{
            width: 100%;
            display: inline-block;

        }
    }
    .content-table label{
        display: inline-block;
        width: 25%;
        font-size: 11px;
        text-transform: unset;
        order: 2;
        &:first-child{
            order: 3;
        }
    }
    .content-table label span{
        font-weight: 600;
    }
    .item-rule{
        overflow-x: hidden;
        .pl-c1{
            padding-left: .2rem;
        }
        .pr-c1{
            padding-right: .2rem;

        }
        &.header-r{
            span{
                line-height: 28px;
                height: 30px;

            }
        }
        span{
            white-space: nowrap;
            line-height: 16px;
            margin-bottom: 0;
            float: left;
            display: inline-block;
            height: 20px;
            width: 100%;
            font-size: 12px;
            font-weight: 400;
            color: #9A9A9A;

        }
    }
    .card{
        .label-row{
            white-space: nowrap;
            line-height: 16px;
            margin-bottom: 0;
            float: left;
        }
    }
    .bol-1{
        border-left: 1px solid #bebebe;
    }
    .bor-1{
        border-right: 1px solid #bebebe;
    }
    .bot-1{
        border-top: 1px solid #bebebe;
    }
    .bob-1{
        border-bottom: 1px solid #bebebe;
    }
</style>
